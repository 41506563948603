// https://bulma.io/documentation/customize/with-node-sass/#6-add-your-own-bulma-styles

@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,900|Montserrat:300,400,500,600,700,800,900|Roboto+Slab:300,400,500,600,700,800,900");

@import "~bulma/sass/utilities/initial-variables.sass";

// From Figma and custom values
$notabene-gray-3: #828282;
$notabene-dark-gray: #BABCBA;
$notabene-green: #46c631;
$notabene-green-accent: #72E85F;
$notabene-background-gray: #F8F8F8;
$notabene-yellow-light: #FFF8DD;
$notabene-light-gray: #F1F2F1;
$notabene-ash-gray: #1F1F1F;
$notabene-black: #000000;
$notabene-red: #FF3A26;
$notabene-white: #ffffff;
$notabene-light-red: #FF6151;
$notabene-cyan: #35BAEE;
// Legacy
$notabene-beige: $notabene-dark-gray;
$notabene-beige-light: $notabene-light-gray;

$light-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
$dark-shadow: 0px 4px 10px $notabene-dark-gray;

$black: $notabene-black;
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);
$grey-darker: $notabene-ash-gray;
$grey-dark: $notabene-gray-3;
$grey: $notabene-dark-gray;
$grey-light: $notabene-background-gray;
$yellow-light: $notabene-yellow-light;
$grey-lighter: $notabene-light-gray;
$grey-lightest: $notabene-light-gray;
$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: $notabene-white;
$orange: $notabene-light-red;
$yellow: hsl(48, 100%, 67%);
$green: $notabene-green-accent;
$turquoise: hsl(171, 100%, 41%);
$cyan: $notabene-cyan;
$blue: hsl(217, 71%, 53%);
$purple: hsl(271, 100%, 71%);
$red: $notabene-red;


// Bulma colors
$scheme-main: $white;
$primary: $black;
$link: $grey;
$warning: $yellow;
$danger: $red;
$success: $green;
$info: $cyan;
$text: $black;
$text-strong: $black;

// Bulma stuff
// $body-background-color: $white;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-bolder: 800;

$radius: 0;
$radius-small: 0;
$radius-large: 0;

$family-sans-serif: 'Montserrat', sans-serif;
$family-primary: $family-sans-serif;

$title-color: $black;
$title-weight: $weight-semibold;
$subtitle-color: $grey;
$subtitle-weight: $weight-semibold;

$breadcrumb-item-color: $notabene-gray-3;

$button-family: 'Montserrat', sans-serif;
// $button-padding-vertical: calc(0.75em);

$navbar-height: calc(0.75rem * 7);
// $navbar-breakpoint: $tablet;
$navbar-background-color: $scheme-main;

$navbar-item-color: $grey;
$navbar-item-active-color: $primary;
$navbar-item-hover-color: $primary;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: transparent;

$navbar-tab-color: $grey;
$navbar-tab-hover-color: $primary;
$navbar-tab-hover-background-color: transparent;
$navbar-tab-active-color: $primary;
$navbar-tab-active-border-bottom-color: $primary;
$navbar-tab-active-border-bottom-width: 6px;
$navbar-tab-active-background-color: transparent;

$tabs-border-bottom-color: $grey;
$tabs-link-color: $grey;
$tabs-link-hover-border-bottom-color: $grey;
$tabs-link-hover-color: $grey;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $primary;

$input-color: $black;
$input-border-color: $notabene-dark-gray;
$input-hover-border-color: $notabene-dark-gray;
$input-background-color: $white;

$label-color: $black;
$label-weight: $weight-semibold;

$menu-item-color: $primary;
$menu-label-color: $primary;
$menu-item-active-color: $white;
$menu-item-active-background-color: $notabene-ash-gray;

$table-background-color: transparent;
$table-cell-border: none;

// $modal-background-background-color: bulmaRgba($scheme-invert, 0.86);
$modal-content-width: unset; // set by styled-components in <Modal.Card /> component
$modal-card-head-background-color: $notabene-light-gray;
$modal-card-head-radius: 0;
$modal-card-head-padding: unset;
$modal-card-body-background-color: $notabene-light-gray;
$modal-card-body-padding: unset;
$modal-card-foot-radius: 0;

$pagination-current-background-color: $primary;
$pagination-current-color: $white;

input[type=checkbox], input[type=radio]  {
  transform: scale(1.4, 1.4);
  padding: 0;
}

@import "~bulma/bulma.sass";

// TODO: prefer partial import
// @import "~bulma/sass/utilities/_all.sass";
// @import "~bulma/sass/base/_all.sass";
// @import "~bulma/sass/grid/_all.sass";
// @import "~bulma/sass/components/navbar.sass";
// @import "~bulma/sass/elements/button.sass";
// @import "~bulma/sass/elements/container.sass";

// For styles/theme.tsx
:root {
  --colors-scheme-main: #{$scheme-main};
  --colors-primary: #{$primary};
  --colors-danger: #{$danger};
  --colors-warning: #{$warning};
  --colors-success: #{$success};
  --colors-link: #{$link};
  --colors-info: #{$info};
  --colors-text: #{$text};
  --colors-white: #{$white};
  --colors-black: #{$black};
  --colors-light-gray: #{$notabene-light-gray};
  --colors-darker-grey: #{$grey-darker};
  --colors-background-gray: #{$notabene-background-gray};
  --colors-yellow-light: #{$notabene-yellow-light};

  --shadows-light: #{$light-shadow};
  --shadows-dark: #{$dark-shadow};

  --weight-light: #{$weight-light};
  --weight-normal: #{$weight-normal};
  --weight-medium: #{$weight-medium};
  --weight-semibold: #{$weight-semibold};
  --weight-bold: #{$weight-bold};
  --weight-bolder: #{$weight-bolder};
}

.is-warning {
  color: #fba433 !important;
}

@keyframes validating-border-fadeIn { 
  0% { box-shadow: rgb(173, 240, 163); } 
  50% { box-shadow: 0px 0px 10px rgb(114, 232, 95); } 
  100% { box-shadow: rgb(173, 240, 163); } 
}

.validating-border {
  animation: validating-border-fadeIn 1.3s infinite;
}

.modal {
  z-index: 150;
}