@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker {
  border: none;
  font-size: 12px;
}

.react-datepicker__header {
  background: none;
  border-bottom: none;
}

.react-datepicker__current-month {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker__day-names {
  margin-top: 0.4rem;
}
.react-datepicker__day-name {
  color: #4f4f4f;
  width: 38px;
}

.react-datepicker__day {
  color: #4f4f4f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
}

.react-datepicker__day--disabled {
  opacity: 0.5;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected {
  font-weight: 500;
  background: #f1fdef;
  border-radius: 50%;
}

.react-datepicker__day--selected {
  border: 1px solid #332d2d;
}

.react-datepicker__day--keyboard-selected {
  border: 1px solid #332d2d;
  background: #f0f0f0;
  border-radius: 50%;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  font-weight: 600;
  background: #f1fdef;
  border-radius: 50%;
}
